@font-face {
  font-family: 'SKODA Next';
  src: url('fonts/SKODANext-LightItalic.eot');
  src: local('SKODA Next Light Italic'), local('fonts/SKODANext-LightItalic'),
    url('fonts/SKODANext-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/SKODANext-LightItalic.woff2') format('woff2'),
    url('fonts/SKODANext-LightItalic.woff') format('woff'),
    url('fonts/SKODANext-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SKODA Next';
  src: url('fonts/SKODANext-Light.eot');
  src: local('SKODA Next Light'), local('fonts/SKODANext-Light'),
    url('fonts/SKODANext-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/SKODANext-Light.woff2') format('woff2'),
    url('fonts/SKODANext-Light.woff') format('woff'),
    url('fonts/SKODANext-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SKODA Next';
  src: url('fonts/SKODANext-Regular.eot');
  src: local('SKODA Next'), local('fonts/SKODANext-Regular'),
    url('fonts/SKODANext-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/SKODANext-Regular.woff2') format('woff2'),
    url('fonts/SKODANext-Regular.woff') format('woff'),
    url('fonts/SKODANext-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SKODA Next';
  src: url('fonts/SKODANext-Italic.eot');
  src: local('SKODA Next Italic'), local('fonts/SKODANext-Italic'),
    url('fonts/SKODANext-Italic.eot?#iefix') format('embedded-opentype'),
    url('fonts/SKODANext-Italic.woff2') format('woff2'),
    url('fonts/SKODANext-Italic.woff') format('woff'),
    url('fonts/SKODANext-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SKODA Next';
  src: url('fonts/SKODANext-Bold.eot');
  src: local('SKODA Next Bold'), local('fonts/SKODANext-Bold'),
    url('fonts/SKODANext-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/SKODANext-Bold.woff2') format('woff2'),
    url('fonts/SKODANext-Bold.woff') format('woff'),
    url('fonts/SKODANext-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SKODA Next';
  src: url('fonts/SKODANext-BoldItalic.eot');
  src: local('SKODA Next Bold Italic'), local('fonts/SKODANext-BoldItalic'),
    url('fonts/SKODANext-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/SKODANext-BoldItalic.woff2') format('woff2'),
    url('fonts/SKODANext-BoldItalic.woff') format('woff'),
    url('fonts/SKODANext-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SKODA Next';
  src: url('fonts/SKODANext-Black.eot');
  src: local('SKODA Next Black'), local('fonts/SKODANext-Black'),
    url('fonts/SKODANext-Black.eot?#iefix') format('embedded-opentype'),
    url('fonts/SKODANext-Black.woff2') format('woff2'),
    url('fonts/SKODANext-Black.woff') format('woff'),
    url('fonts/SKODANext-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SKODA Next';
  src: url('fonts/SKODANext-BlackItalic.eot');
  src: local('SKODA Next Black Italic'), local('fonts/SKODANext-BlackItalic'),
    url('fonts/SKODANext-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/SKODANext-BlackItalic.woff2') format('woff2'),
    url('fonts/SKODANext-BlackItalic.woff') format('woff'),
    url('fonts/SKODANext-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
