@import '../../assets/styles/_variables.scss';

.dialog__info-icon {
  background-color: #4ba82e;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
}

.logout-page__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logout-page__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
